<script>
import OneSignalNewView from './OneSignalNewView'

export default {
  name: 'OneSignalEditView',
  extends: OneSignalNewView,
  methods: {
    async getOneSignal () {
      await this.$store.dispatch('oneSignal/fetchOne', this.$route.params.id)
      this.oneSignal = this.$store.getters['oneSignal/detail']
    }
  },
  created () {
    this.getOneSignal()
  }
}
</script>
